import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import Content, { HTMLContent } from '../components/Content'
import BasicLayout from "../components/layout/BasicLayout";
import Avatar from "../components/Avatar";
import avatarImg from "../img/avatar.jpg";
import Button from "../components/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export const BlogPostTemplate = ({
                                     content,
                                     contentComponent,
                                     description,
                                     title,
                                     featuredimage,
                                     date
                                 }) => {
    const PostContent = contentComponent || Content
    const featuredImg = featuredimage ?
        (<Img className="p-blog--feature" fluid={featuredimage.childImageSharp.fluid} alt={title} />) : null;

    return (
        <section>
            <article>
                <header>
                    <h1 className="copy-header copy-header--xl">
                        {title}
                    </h1>

                    <div className="p-blog--author">
                        <div className="l-flex">
                            <div>
                                <Avatar image={avatarImg} width={"70px"} height={"70px"}></Avatar>
                            </div>

                            <div className="l-flex l-flex--column">
                                <Link to="/about" className="no-border copy-header--xs" >
                                    <span>John Richardson</span>
                                </Link>

                                <p>
                                    A pragmatic software developer with ~ 10 years experience. PHP/Vue JS advocate.
                                </p>

                                <p><a href="https://twitter.com/JRDevelop">@JRdevelop</a> {date}</p>
                            </div>
                        </div>
                    </div>
                </header>

                {featuredImg}

                <PostContent content={content} />

                <section className={"p-blog--end l-component"}>
                    <Button type={"text"} link="/blog">
                        <FontAwesomeIcon icon={faArrowLeft}  size="s" /> <span>Back</span>
                    </Button>
                </section>
            </article>
        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
}

const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <BasicLayout pageName={"blog"} title={post.frontmatter.title}>
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                title={post.frontmatter.title}
                featuredimage={post.frontmatter.featuredimage}
                date={post.frontmatter.date}
            />
        </BasicLayout>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
            childImageSharp {
                fluid(maxWidth: 1680, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
      }
    }
  }
`
