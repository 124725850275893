import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from './../Navbar';
import './../../styles/all.scss'
import PropTypes from "prop-types";
import {useGlobalState} from "./../../store/app";
import useSiteMetadata from "../SiteMetadata";
import Footer from '../Footer';

const TemplateWrapper = ({ pageName, footerCta = true, title = null, children }) => {
  const [navOpen, setNavOpen] = useGlobalState('navOpen');
  const { siteTitle, description } = useSiteMetadata();
  const metaTitle = title ? title : siteTitle;

  const navBar = React.createRef();

  const handleNavBarOpen = (open) => {
      setNavOpen(open);
  };

  return (
      <div className={`p-basic p-${pageName}`}>
          <Helmet>
              <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
              <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1"/>
              <meta name="description" content={description}/>
              <title>{metaTitle}</title>
          </Helmet>

          <Navbar onToggle={handleNavBarOpen} ref={navBar} />

          <main className={"l-container l-container--indented-constrained"}>
              {children}
          </main>

          <Footer showCta={footerCta}/>
      </div>
  )
}

TemplateWrapper.propTypes = {
    pageName: PropTypes.string,
    footerCta: PropTypes.bool,
    title: PropTypes.string
}

export default TemplateWrapper
